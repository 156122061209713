/**
 * @fileoverview Check whether the given variable is a string or not.
 */

"use strict";

/**
 * Check whether the given variable is a boolean or not.
 *  If the given variable is a boolean, return true.
 * @param {*} obj - Target for checking
 * @returns {boolean} Is boolean?
 * @memberof module:type
 */
function isBoolean(obj) {
  return typeof obj === "boolean" || obj instanceof Boolean;
}

export default isBoolean;
