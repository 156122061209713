const Category = (sections) => {

    return `
        <div data-component="category" class="pl-5 text-[#737373] fill-[#737373] font-normal text-center break-keep" style="margin-top:20px;margin-bottom: 40px;">
            <div class="categoryMobile">
                <div class="category-click flex flex-col items-center gap-y-2 text-[#3db051] font-bold fill-[#3db051] cursor-pointer" data-seq="0">
                    <div class="rounded-full bg-[#f8f8f8]" style="padding:9px;">
                        <svg width="30" height="30" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_483_1119)">
                                <path d="M4.97496 30C4.74124 29.9499 4.50751 29.8998 4.27379 29.8331C1.76962 29.2154 0 26.9783 0 24.3906C0 18.1302 0 11.8698 0 5.60935C0 2.80467 2.07012 0.434057 4.8581 0.0500835C5.09182 0.0166945 5.34224 0 5.59266 0C10.1836 0 14.7913 0 19.3823 0C22.1536 0 24.5242 2.03673 24.9249 4.79132C25.0084 5.30885 24.9917 5.84307 24.9917 6.3606C24.9917 7.32888 24.9917 8.31386 24.9917 9.28214C24.9917 9.63272 24.8414 9.86644 24.5409 9.94992C24.2738 10.0334 24.0401 9.94992 23.8898 9.74958C23.7897 9.59933 23.7229 9.399 23.7229 9.21536C23.7229 8.04674 23.7229 6.89482 23.7229 5.72621C23.7229 3.45576 22.3038 1.73623 20.0835 1.30217C19.8164 1.25209 19.5159 1.23539 19.2321 1.23539C14.7245 1.23539 10.2337 1.23539 5.72621 1.23539C3.45576 1.23539 1.73623 2.67112 1.30217 4.89149C1.25209 5.19199 1.23539 5.49249 1.23539 5.79299C1.23539 11.9199 1.23539 18.0634 1.23539 24.1903C1.23539 26.5442 2.80467 28.197 4.62437 28.5977C5.02504 28.6811 5.4591 28.7145 5.87646 28.7145C8.197 28.7145 10.5175 28.7145 12.8548 28.7145C12.9716 28.7145 13.0885 28.7145 13.2053 28.7145C13.5058 28.7479 13.6895 28.9316 13.7396 29.2154C13.7897 29.4825 13.6895 29.6995 13.4725 29.8497C13.4224 29.8831 13.3556 29.9165 13.3055 29.9499H4.97496V30Z" />
                                <path d="M29.1649 30C28.998 29.8665 28.831 29.7329 28.6808 29.5827C27.412 28.3139 26.1432 27.0451 24.8745 25.7763C24.8077 25.7095 24.7242 25.6261 24.6407 25.5593C21.0681 28.5643 16.0097 27.9466 13.1549 24.6411C10.4337 21.4691 10.6174 16.6277 13.6224 13.6394C16.6274 10.6511 21.4521 10.4842 24.624 13.2054C27.9128 16.0434 28.5305 21.0852 25.4755 24.7246C25.5589 24.7746 25.6591 24.8247 25.7426 24.8915C27.0113 26.1603 28.2801 27.4291 29.5489 28.6979C29.6992 28.8481 29.8327 29.015 29.9663 29.182V30H29.1482H29.1649ZM12.5038 19.3656C12.5038 23.1386 15.5923 26.2438 19.3653 26.2438C23.1382 26.2438 26.2434 23.1553 26.2434 19.3823C26.2434 15.6094 23.1716 12.5376 19.382 12.5042C15.609 12.4875 12.5205 15.576 12.5038 19.3656Z" />
                                <path d="M15.6092 7.49623C14.4072 7.49623 13.1885 7.49623 11.9865 7.49623C11.5358 7.49623 11.2686 7.2625 11.252 6.87853C11.252 6.49456 11.5024 6.24414 11.9698 6.24414C14.4072 6.24414 16.8446 6.24414 19.282 6.24414C19.7328 6.24414 19.9999 6.49456 19.9999 6.87853C19.9999 7.2625 19.7328 7.49623 19.282 7.49623C18.0633 7.49623 16.8446 7.49623 15.6259 7.49623H15.6092Z" />
                                <path d="M6.87778 12.4883C7.26175 12.4883 7.66242 12.4883 8.0464 12.4883C8.46376 12.4883 8.73087 12.7554 8.73087 13.1227C8.73087 13.49 8.46376 13.7571 8.0464 13.7571C7.26175 13.7571 6.46042 13.7571 5.67578 13.7571C5.25842 13.7571 4.97461 13.49 4.97461 13.1227C4.97461 12.7721 5.25842 12.4883 5.67578 12.4883C6.07645 12.4883 6.47711 12.4883 6.87778 12.4883Z" />
                                <path d="M6.86174 18.748C7.2624 18.748 7.67977 18.748 8.08043 18.748C8.46441 18.748 8.71482 18.9818 8.73152 19.349C8.73152 19.6996 8.51449 19.9834 8.13052 20.0001C7.2791 20.0001 6.44437 20.0001 5.59295 20.0001C5.24237 20.0001 4.97526 19.6996 4.99195 19.3824C4.99195 19.0485 5.27576 18.7647 5.64304 18.7647C6.04371 18.7647 6.46107 18.7647 6.86174 18.7647V18.748Z" />
                                <path d="M6.86099 7.49623C6.46033 7.49623 6.05966 7.49623 5.65899 7.49623C5.27502 7.49623 4.99121 7.22912 4.99121 6.87853C4.99121 6.54464 5.25832 6.26084 5.6256 6.24414C6.46033 6.24414 7.27836 6.24414 8.11308 6.24414C8.51375 6.24414 8.76417 6.52795 8.74747 6.89523C8.74747 7.2625 8.48036 7.49623 8.063 7.49623C7.66233 7.49623 7.26166 7.49623 6.86099 7.49623Z" />
                                <path d="M18.4977 22.5041C17.88 22.5041 17.3291 22.2704 16.8783 21.8363C16.3274 21.3021 15.7765 20.7512 15.2255 20.2169C14.925 19.9164 14.9083 19.5492 15.1588 19.2821C15.4259 19.0149 15.8099 19.0316 16.1104 19.3321C16.6279 19.833 17.1454 20.3505 17.6462 20.8513C18.1972 21.3856 18.7982 21.3856 19.3491 20.8513C20.4175 19.8163 21.486 18.7979 22.5544 17.7629C22.7214 17.5959 22.9384 17.429 23.1721 17.5124C23.3558 17.5792 23.5728 17.7295 23.673 17.8964C23.8065 18.1301 23.723 18.3972 23.5227 18.5809C22.9718 19.1151 22.4209 19.6493 21.8533 20.1836C21.2856 20.7345 20.7013 21.3021 20.117 21.853C19.6663 22.2871 19.1154 22.4874 18.481 22.4874L18.4977 22.5041Z" />
                            </g>
                            <defs>
                                <clipPath id="clip0_483_1119">
                                    <rect width="30" height="30" fill="white"/>
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                    <div>전체보기</div>
                </div>
                ${sections.map(({category, title}, index, arr) => {
                    if(index === arr.length-1) {
                        return `
                            <div class="category-click pr-5 flex flex-col items-center gap-y-2 cursor-pointer" data-seq="${index+1}">
                                <div class="rounded-full bg-[#f8f8f8]" style="padding: 9px;width: 48px;height: 48px;">
                                    ${category.img_icon ?? ''}
                                </div>
                                <div>${category.name}</div>
                            </div>
                        `
                    } else {
                        return `
                            <div class="category-click flex flex-col items-center gap-y-2 cursor-pointer" data-seq="${index+1}">
                                <div class="rounded-full bg-[#f8f8f8]" style="padding: 9px;width: 48px;height: 48px;">
                                    ${category.img_icon ?? ''}
                                </div>
                                <div>${category.name}</div>
                            </div>
                        `
                    }
                }).join('')}
            </div>
        </div>
    `
}

export default Category