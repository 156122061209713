export const TYPE_USER = {
  ANNONYMOUS: 0,
  ADMIN: 1,
  OPERATOR: 2,
  PRODUCER: 4,
  TEACHER: 8,
  STUDENT: 16,
  PARENT: 32,
  USER: 64, // 아무 유형도 아님..?
  MANAGER: 128,
};
